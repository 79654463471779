// extracted by mini-css-extract-plugin
export var modularHomesBody = "modularHomes-module--modularHomesBody--4WgKR";
export var modularHomesFaq = "modularHomes-module--modularHomesFaq--q+EM-";
export var modularHomesFaqBody = "modularHomes-module--modularHomesFaqBody--vU6Pm";
export var modularHomesFaqHeading = "modularHomes-module--modularHomesFaqHeading--pJJvg";
export var modularHomesFaqInner = "modularHomes-module--modularHomesFaqInner--qyP-K";
export var modularHomesFaqs = "modularHomes-module--modularHomesFaqs--Vu4Ie";
export var modularHomesGrid = "modularHomes-module--modularHomesGrid--jOS0L";
export var modularHomesHeroImageWrapper = "modularHomes-module--modularHomesHeroImageWrapper--cTga+";
export var modularHomesInner = "modularHomes-module--modularHomesInner--2-rWZ";
export var modularHomesSectionHeading = "modularHomes-module--modularHomesSectionHeading--ED8Xm";