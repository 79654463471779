import React from 'react';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Button from '@webAtoms/Button/Button';
import Container from '@webAtoms/Container/Container';
import BrochureForm from '@webSections/BrochureForm';
import Accordion from '@webMolecules/Accordion/Accordion';
import UpNext from '@webMolecules/UpNext/UpNext';
import ModularHomeDesigns from '@webOrganisms/ModularHomeDesigns/ModularHomeDesigns';
import FeaturedBuilds from '@webSections/FeaturedBuilds';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import * as Route from '@routes';
import { render } from '@helpers/markdown';
import * as styles from './modularHomes.module.scss';

export interface ModularHomesProps {
  title: string;
  body: string;
  heroImage: IGatsbyImageData;
  columns: Column[];
  faqSections: FaqSection[];
}

export interface Column {
  title: string;
  body: string;
}

export interface FaqSection {
  title: string;
  body: string;
  parts: Part[];
}

export interface Part {
  title: string;
  body: string;
}

const ModularHomes: React.FC<ModularHomesProps> = ({
  title,
  body,
  heroImage,
  columns,
  faqSections,
}) => {
  return (
    <Box>
      <PageHeading title={title}>
        <Box className={styles.modularHomesBody} htmlContent={body} />
      </PageHeading>
      <Container>
        <Box className={styles.modularHomesInner}>
          <Box className={styles.modularHomesHeroImageWrapper}>
            <GatsbyImage loading="lazy" alt={title} image={heroImage} />
          </Box>

          <Box className={styles.modularHomesGrid}>
            <Box>
              {columns.map((column, i) => (
                <Box key={i}>
                  <Box className={styles.modularHomesSectionHeading}>
                    <h2>{column.title}</h2>
                    <Box htmlContent={render(column.body, {})} />
                  </Box>
                </Box>
              ))}
            </Box>

            <Box className={styles.modularHomesFaqs}>
              {faqSections.map(section => (
                <Box className={styles.modularHomesFaq}>
                  <Box
                    key={section.title}
                    className={styles.modularHomesFaqInner}
                  >
                    <Box className={styles.modularHomesFaqHeading}>
                      {section.title && <h2>{section.title}</h2>}
                      <Box htmlContent={render(section.body, {})} />
                    </Box>
                    <Box className={styles.modularHomesFaqBody}>
                      {section.parts.map(part => (
                        <Accordion key={part.title} title={part.title}>
                          <Box
                            className={styles.modularHomesFaqBody}
                            htmlContent={render(part.body)}
                          />
                        </Accordion>
                      ))}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box mobile paddingTop={Spacing.Xl}>
          <Button
            fluid
            intent="primary"
            icon="arrowRight"
            href={Route.contactUs()}
          >
            Enquire
          </Button>
        </Box>
      </Container>

      <Box paddingTop={Spacing.Xl2} paddingBottom={Spacing.Xl2}>
        <BrochureForm />
      </Box>

      <ModularHomeDesigns />

      <FeaturedBuilds />

      <UpNext
        links={[
          {
            label: 'Modular homes',
            href: Route.designs(),
          },
          { label: 'Our approach', href: Route.ourApproach() },
        ]}
      />
    </Box>
  );
};

export default ModularHomes;
